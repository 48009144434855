/******************************************************************************
*  @Filename: api.service.ts
*  @Date: 17-12-2021
*  @Author: Adrien Lanco
*
*  Description: Manage requests to API
*******************************************************************************/

/* SUMMARY
* IMPORT
  * Angular/Ionic
  * Services
  * Component
  * Node modules
* VARIABLES
* FUNCTIONS
  * Name: request
*/

/* IMPORT */
  /* Angular/Ionic */
  import { Injectable } from '@angular/core';
  import { HttpClient, HttpHeaders } from '@angular/common/http';

  import { environment } from '../../../environments/environment';
  /***/

  /* Services */
  import { SessionService } from '../../services/session/session.service';
  /***/
/***/

@Injectable({
  providedIn: 'root'
})
export class ApiService {
/* VARIABLES */
  constructor(private http: HttpClient,
              private session: SessionService) {
    this.session.api = this;
  }
/***/

/* FUNCTIONS */

  /*
  * Name: request
  * Description: Make request
  *
  * Args:
  * - method (String): Request method
  * - url (String): Request url
  * - data (Object): Data to send with request
  *
  * Return (Any | String): Data sended by API or error message
  */
  public request(method, url, data): Promise<any> {
    return new Promise((resolve, reject) => {
      let options = {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          'Authorization': this.session.getToken()
        })
      };

      let body = data !== null ? data : options;
      let api = environment.production ? environment.api : environment.api_dev;
      this.http[method.toLowerCase()](api+url, body, options)
      .toPromise().then((ret) => {
        resolve(ret);
      }).catch((err) => {
        let message = err.statusText;
        if (err.error.message) message = Array.isArray(err.error.message) ? err.error.message[0] : err.error.message;

        if (err.status == 401 && url != '/api/users/connect') this.session.disconnect(false);

        reject(message.charAt(0).toUpperCase() + message.slice(1));
      });
    });
  }
  /***/
/***/
}
